// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-payment-cowork-js": () => import("./../../../src/pages/payment-cowork.js" /* webpackChunkName: "component---src-pages-payment-cowork-js" */),
  "component---src-pages-payment-office-js": () => import("./../../../src/pages/payment-office.js" /* webpackChunkName: "component---src-pages-payment-office-js" */),
  "component---src-pages-ui-js": () => import("./../../../src/pages/UI.js" /* webpackChunkName: "component---src-pages-ui-js" */),
  "component---src-templates-fast-page-js": () => import("./../../../src/templates/fast-page.js" /* webpackChunkName: "component---src-templates-fast-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */)
}

